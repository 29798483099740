// const tim_colors = [
//     { name: 'transparent' },
//     { name: 'primary' },
//     { name: 'info' },
//     { name: 'success' },
//     { name: 'warning' },
//     { name: 'danger' },
//     { name: 'rose' },
//     { name: 'white' },
//     { name: 'twitter' },
//     { name: 'facebook' },
//     { name: 'google' },
//     { name: 'linkedin' },
//     { name: 'pinterest' },
//     { name: 'youtube' },
//     { name: 'tumblr' },
//     { name: 'github' },
//     { name: 'behance' },
//     { name: 'dribbble' },
//     { name: 'reddit' }

// ]



const tim_colors = ["transparent", "primary","rose","dark","info","success","warning","danger" ]
// const tim_colors = [
//     'transparent',
//     'primary',
//     'info',
//     'success',
//     'warning',
//     'danger',
//     'rose',
//     'white',
//     'twitter',
//     'facebook',
//     'google',
//     'linkedin',
//     'pinterest',
//     'youtube',
//     'tumblr',
//     'github',
//     'behance',
//     'dribbble',
//     'reddit'

// ]
export { tim_colors }
