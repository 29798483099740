const css_fontfamily = [
    "disabled", 
    "Bebas Neue",
    "Roboto",
    "Futura",
    "Arial, sans-serif",
    "Helvetica, sans-serif",
    "Verdana, sans-serif",
    "Trebuchet MS, sans-serif",
    "Gill Sans, sans-serif",
    "Noto Sans, sans-serif",
    "Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif",
    "Optima, sans-serif",
    "Arial Narrow, sans-serif",
]

export { css_fontfamily }