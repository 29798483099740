export const html_type = [
    'CT_SectionHeader_03',
    'CT_SectionFeatures_02',
    'CT_SectionFeatures_03',
    'CT_SectionFeatures_05',
    'CT_SectionTeams_03',
    'CT_SectionTeams_05',
    'CT_SectionProjects_01',
    'CT_SectionProjects_02',
    'CT_SectionProjects_03',
    'CT_SectionTestimonials_01',
    'MM_SectionParallax_01',
    'CT_SectionComponents_01',
    'CT_SectionContent_01']
