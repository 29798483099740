const taxonomy_type = [
    { name: 'slide.tag.1' },
    { name: 'product.tag.1' },
    { name: 'product.type.1' },
    { name: 'product.variant.1' },
    { name: 'product.variant.2' },
    { name: 'productgroup.cat.1' },
    { name: 'taxonomy.type' }
]

export { taxonomy_type }