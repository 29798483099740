const css_textalign = [
    "disabled", 
    "inherit",
    "left", 
    "right",
    "center",
    "justify",
    "initial",
]

export { css_textalign }