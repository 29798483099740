const animatecss_types = [
    "none",
    "animate__bounce", 
    "animate__flash", 
    "animate__pulse",
    "animate__rubberBand",
    "animate__shakeX",
    "animate__shakeY",
    "animate__headShake",
    "animate__swing",
    "animate__tada",
    "animate__wobble", 
    "animate__jello", 
    "animate__heartBeat", 
    "animate__backInDown", 
    "animate__backInLeft", 
    "animate__backInRight", 
    "animate__backInUp", 
    "animate__backOutDown", 
    "animate__backOutLeft", 
    "animate__backOutRight", 
    "animate__backOutUp",
    "animate__bounceIn",
    "animate__bounceInDown", 
    "animate__bounceInLeft", 
    "animate__bounceInRight", 
    "animate__bounceInUp", 
    "animate__bounceOut", 
    "animate__bounceOutDown", 
    "animate__bounceOutLeft", 
    "animate__bounceOutRight", 
    "animate__bounceOutUp", 
    "animate__fadeIn", 
    "animate__fadeInDown", 
    "animate__fadeInDownBig", 
    "animate__fadeInLeft", 
    "animate__fadeInLeftBig", 
    "animate__fadeInRight", 
    "animate__fadeInRightBig", 
    "animate__fadeInUp", 
    "animate__fadeInUpBig", 
    "animate__fadeInTopLeft", 
    "animate__fadeInTopRight", 
    "animate__fadeInBottomLeft", 
    "animate__fadeInBottomRight",
    "animate__fadeOut", 
    "animate__fadeOutDown", 
    "animate__fadeOutDownBig", 
    "animate__fadeOutLeft", 
    "animate__fadeOutLeftBig", 
    "animate__fadeOutRight", 
    "animate__fadeOutRightBig", 
    "animate__fadeOutUp", 
    "animate__fadeOutUpBig", 
    "animate__fadeOutTopLeft", 
    "animate__fadeOutTopRight", 
    "animate__fadeOutBottomLeft", 
    "animate__fadeOutBottomRight",
    "animate__flip", 
    "animate__flipInX", 
    "animate__flipInY", 
    "animate__flipOutX", 
    "animate__flipOutY", 
    "animate__lightSpeedInRight", 
    "animate__lightSpeedInLeft", 
    "animate__lightSpeedOutRight", 
    "animate__lightSpeedOutLeft", 
    "animate__rotateIn", 
    "animate__rotateInDownLeft", 
    "animate__rotateInDownRight",
    "animate__rotateInUpLeft", 
    "animate__rotateInUpRight",
    "animate__rotateOut", 
    "animate__rotateOutDownLeft", 
    "animate__rotateOutDownRight",
    "animate__rotateOutUpLeft", 
    "animate__rotateOutUpRight", 
    "animate__hinge", 
    "animate__jackInTheBox", 
    "animate__rollIn", 
    "animate__rollOut", 
    "animate__zoomIn", 
    "animate__zoomInDown", 
    "animate__zoomInLeft", 
    "animate__zoomInRight", 
    "animate__zoomInUp",
    "animate__zoomOut", 
    "animate__zoomOutDown", 
    "animate__zoomOutLeft", 
    "animate__zoomOutRight", 
    "animate__zoomOutUp",
    "animate__slideIn", 
    "animate__slideInDown", 
    "animate__slideInLeft", 
    "animate__slideInRight", 
    "animate__slideInUp",
    "animate__slideOut", 
    "animate__slideOutDown", 
    "animate__slideOutLeft", 
    "animate__slideOutRight", 
    "animate__slideOutUp", 

]
export { animatecss_types }